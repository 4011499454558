import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"

import celebrate from "../images/celebrate.svg"

export default function custom404() {
  return (
    <Layout>
      <GatsbySeo
        noindex={true}
        nofollow={true}
        title="Thank You"
        description="Thank you for your contact form submission"
        canonical="https://yourtherapydoncaster.co.uk/"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/contact",
         title: "Get in touch",
         description: "Get in touch to book a therapy appointment or find out more about counselling",
         site_name: "YourTherapy",
       }}
      />
      <Container fluid>
        <Row >
          <Col xs={12} sm={{span:10,offset:1}} md={{span:6,offset:3}} className="text-center mt-2 mb-5">
            <h1 class="display-1">Thank You</h1>
            <p>I have recieved your message and will be in touch soon</p>
            <Button href="/" className="my-3 py-2 text-uppercase font-weight-bolder" variant="outline-dark">
              Go home
            </Button>
            <Image src={celebrate} fluid alt="" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
